import React, {useState} from 'react';
import amazon from '../../assets/images/examples/giftCards/amazon.png';
import rakuten from '../../assets/images/examples/giftCards/rakuten.png';
import linePrePaid from '../../assets/images/examples/giftCards/line.png';
import lineCoupon from '../../assets/images/examples/giftCards/line-coupon.jpg';
import nintendo from '../../assets/images/examples/giftCards/nintendo.jpg';
import bitCash from '../../assets/images/examples/giftCards/bit-cash.png';
import dazn from '../../assets/images/examples/giftCards/dazn.png';
import dmm from '../../assets/images/examples/giftCards/dmm.jpg';
import googlePlay from '../../assets/images/examples/giftCards/google-play.png';
import gree from '../../assets/images/examples/giftCards/gree.png';
import mobaCoin from '../../assets/images/examples/giftCards/moba-coin.jpg';
import netflix from '../../assets/images/examples/giftCards/netflix.png';
import spotify from '../../assets/images/examples/giftCards/spotify.png';
import webmoney from '../../assets/images/examples/giftCards/web-money.png';
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';
import Drawer from '../../components/UiComponent/drawer';
import {StaticImage} from 'gatsby-plugin-image';

import WrapperStyle from './examples.style';
import PopularExamples from './PopularExample';
import ScriptTag from 'react-script-tag';

const OurSolutions = () => {
    const [visible, setVisible] = useState(false);
    const [promotionId, setPromotionId] = useState(null);
    const [drawerTitle, setDrawerTitle] = useState(null);
    const showDrawer = (id, title) => {
        setVisible(true);
        setPromotionId(id);
        setDrawerTitle(title)
    };
    return (
      <WrapperStyle>
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h1>キャンペーンでクーポンを配布する
                    </h1>
                    <p>
                        1887年にコカ・コーラ社が初めて紙のクーポンキャンペーンを行って以来、多くのブランドが利用してきました。リタッチでは現在のニーズにあったデジタルクーポン配布するキャンペーンを簡単に配布できます。
                    </p>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <a data-retach-widget="embed" href="https://retach.biz/promotions/ad1131cc1129c8ca26" rel="nofollow">リタッチ</a>
                        <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
                    </div>
                </div>
            </div>
        </section>
          <section className="process-area ptb-100 bg-f1f8fb">
              <div className="container">
                  <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Coupon / Gift / Pre-Paid
                    </span>
                      <h2>賞品として提供できるクーポン</h2>
                      <p>お客様で発行されたECサイトで使用するクーポンコードから、Amazonギフトなど多数提供できます。</p>
                  </div>

                  <div className="row">
                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon" style={{color: '#a3a3a3'}}>
                                  <i className="fas fa-shopping-cart fa-4x"></i>
                              </div>
                              <h3>お客様発行<br/>クーポンコード</h3>
                          </div>
                      </div>

                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={amazon} alt="feature" />
                              </div>
                              <h3>Amazon ギフト</h3>
                          </div>
                      </div>

                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={googlePlay} alt="feature" />
                              </div>
                              <h3>Google Play<br/>ギフト</h3>
                          </div>
                      </div>

                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={rakuten} alt="feature" />
                              </div>
                              <h3>楽天ギフト</h3>
                          </div>
                      </div>

                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={linePrePaid} alt="feature" />
                              </div>
                              <h3>LINEプリペイド</h3>
                          </div>
                      </div>

                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={lineCoupon} alt="feature" />
                              </div>
                              <h3>LINE@クーポン</h3>
                          </div>
                      </div>
                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={netflix} alt="feature" />
                              </div>
                              <h3>Netflix<br/>プリペイド</h3>
                          </div>
                      </div>
                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={dazn} alt="feature" />
                              </div>
                              <h3>DAZNプリペイド</h3>
                          </div>
                      </div>
                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={spotify} alt="feature" />
                              </div>
                              <h3>Spotifyギフト</h3>
                          </div>
                      </div>
                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={bitCash} alt="feature" />
                              </div>
                              <h3>BitCashギフト</h3>
                          </div>
                      </div>
                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={webmoney} alt="feature" />
                              </div>
                              <h3>WebMoney<br/>ギフト</h3>
                          </div>
                      </div>
                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={mobaCoin} alt="feature" />
                              </div>
                              <h3>Mobaコイン<br/>デジタル</h3>
                          </div>
                      </div>
                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={dmm} alt="feature" />
                              </div>
                              <h3>DMMギフト</h3>
                          </div>
                      </div>
                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={nintendo} alt="feature" />
                              </div>
                              <h3>ニンテンドー<br/>プリペイド</h3>
                          </div>
                      </div>
                      <div className="col-lg-2 col-sm-4">
                          <div className="single-services-item-box">
                              <div className="icon">
                                  <img src={gree} alt="feature" />
                              </div>
                              <h3>GREEプリペイド</h3>
                          </div>
                      </div>
                  </div>
                  <div className="attention">
                      <p >※リタッチでギフト・プリペイドの販売は行っておりません。</p>
                  </div>
              </div>
          </section>
          <section className="ptb-100 top-question" >
              <div className="container">
                  <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Question
                    </span>
                      <h2 id="question">よくあるご質問</h2>
                  </div>
                  <div className="faq-accordion">
                      <Accordion allowMultipleExpanded={true}>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      他のサービスで用意した大量のURLでクーポンを配布したいのですが可能ですか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。管理画面から配布するURLをCSVファイルかテキストファイルでアップロードしてください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      配布できるクーポンに上限はありますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。プランによって上限があります。詳しくは<a href='/pricing' target="_blank">料金ページの「インスタントウィン」の「賞品・懸賞数」</a>をご覧ください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      応募者全員ではなく、くじ引きのように落選を含めることはできますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。可能です。当選確率を設定できますので、ご希望の当選確率を設定してください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      用意したクーポンが全て提供された場合はどうなりますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      設定したクーポンの上限値に達した場合、終了となりますので応募フォームから応募できなくなります。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      応募者の住所や電話番号を取得できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。応募者に入力して頂く項目は自由に変更できます。応募者情報の設定についてはこちらをご覧ください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>
                  </div>
              </div>
          </section>
          <PopularExamples exclude={['coupon']} showDrawer={showDrawer}/>
          <Drawer visible={visible}id={promotionId} drawerTitle={drawerTitle} setVisible={setVisible}/>
      </WrapperStyle>
    )
};

export default OurSolutions