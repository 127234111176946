import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import ContentBanner from '../../components/Common/ContentBanner'
import Footer from "../../components/App/Footer"
import CouponCampaign from '../../components/Examples/CouponCampaign'
import SEO from '../../components/App/SEO';
import ProjectStartArea from '../../components/Common/StartProject';
import exampleMetaImage from '../../assets/images/examples/eCommerce/e-commerce.jpg';

const Examples = () => {

  return (
	<Layout>
	  <SEO
		title="クーポン配布の事例・サンプル"
		desc="リタッチでは集客するためのクーポンを効率的に配布し集約するキャンぺ０ンについての事例やサンプルが多くあります。"
		banner={exampleMetaImage}
		pathname={'/examples/coupon'}
		type={'Article'}
		shortName="クーポン配布キャンペーンサンプル"
		createdAt="2021-03-02"
		updatedAt="2021-08-04"
	  />
	  <Navbar />
	  <ContentBanner
		pageTitle="クーポン配布"
		homePageText="Home"
		homePageUrl="/"
		activePageText="クーポン配布"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		}]}
	  />
	  <CouponCampaign />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples